<script setup>
import StandardLayout from "@/Layouts/StandardLayout.vue";
import { useI18n } from "vue-i18n";
import { ref } from "vue";

const { t } = useI18n();
</script>

<template>
    <StandardLayout>
        <div class="body-container overflow-auto min-h-screen font-barlow">
            <div class="absolute top-0 z-0 w-full">
                <img
                    src="/images/landing/bg-mobile.png"
                    alt="bg"
                    class="w-full md:hidden"
                />
                <img
                    src="/images/landing/bg-desktop.png"
                    alt="bg"
                    class="w-full hidden md:block"
                />
            </div>

            <div class="header text-right z-1 relative">
                <h1 v-html="t('terms_conditions_head')" class="font-[700]"></h1>
            </div>

            <div class="page-container z-1 relative text-[#C6C6C6]">
                <p>
                    1. T&ecirc;n sự kiện: SỰ KIỆN TR&Igrave;NH
                    DIỄN FLYCAM &ldquo;TH&Iacute;CH TH&Igrave;
                    NH&Iacute;CH&rdquo; &quot;thiết bị bay kh&ocirc;ng người
                    l&aacute;i&quot; &nbsp; &nbsp;(drone)
                </p>
                <p>
                    &nbsp; &nbsp; &nbsp;(sau đ&acirc;y gọi tắt l&agrave; Sự kiện
                    &ldquo;Th&iacute;ch Th&igrave; Nh&iacute;ch&rdquo;)
                </p>
                <p>
                    2.  Đơn vị tổ chức: C&ocirc;ng ty
                    TNHH Tiếp thị Ti&ecirc;u Điểm
                </p>
                <p>3. Thời gian:</p>
                <p>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; a. Đăng k&yacute;
                    nhận v&eacute; tham gia chương tr&igrave;nh: từ ng&agrave;y
                    15/05/2023 đến hết ng&agrave;y 31/05/2023
                </p>
                <p>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; b. Diễn ra sự
                    kiện: Th&agrave;nh phố &nbsp;H&agrave; Nội - Cụ thể địa điểm
                    sẽ được c&ocirc;ng bố sau
                </p>
                <p>
                    4. Địa b&agrave;n (phạm vi): Th&agrave;nh phố
                    H&agrave; Nội
                </p>
                <p>5. H&igrave;nh thức tham gia:</p>
                <p>
                    &middot; &nbsp; &nbsp; &nbsp;Người chơi qu&eacute;t
                    m&atilde; QR tr&ecirc;n c&aacute;c biểu tượng ng&oacute;n
                    tay c&aacute;i m&agrave;u đỏ xuất hiện ngẫu nhi&ecirc;n
                    tr&ecirc;n địa b&agrave;n th&agrave;nh phố H&agrave; Nội để
                    truy cập website chương tr&igrave;nh Th&iacute;ch Th&igrave;
                    Nh&iacute;ch.
                </p>
                <p>
                    &middot; &nbsp; &nbsp; &nbsp;Người chơi thực hiện
                    đ&uacute;ng theo c&aacute;c bước được y&ecirc;u cầu
                    tr&ecirc;n website để đăng k&yacute; trở th&agrave;nh người
                    may mắn nhận v&eacute; miễn ph&iacute; tham gia sự kiện
                    TH&Iacute;CH TH&Igrave; NH&Iacute;CH v&agrave;o ng&agrave;y
                    03/06/2023 tại H&agrave; Nội.
                </p>
                <p>
                    &middot; &nbsp; &nbsp; &nbsp;Website sẽ tự động quay
                    v&ograve;ng quay may mắn v&agrave; chọn người tr&uacute;ng
                    giải ngẫu nhi&ecirc;n.
                </p>
                <p>
                    &middot; &nbsp; &nbsp; &nbsp;Hệ thống sẽ th&ocirc;ng
                    b&aacute;o người chơi c&oacute; may mắn đăng k&yacute;
                    v&eacute; th&agrave;nh c&ocirc;ng hay kh&ocirc;ng.
                </p>
                <p>
                    &middot; &nbsp; &nbsp; &nbsp;Nếu người chơi được th&ocirc;ng
                    b&aacute;o đăng k&yacute; th&agrave;nh c&ocirc;ng, Ban Tổ
                    Chức chương tr&igrave;nh sẽ li&ecirc;n hệ với người chơi
                    th&ocirc;ng qua th&ocirc;ng tin c&aacute; nh&acirc;n được
                    cung cấp để x&aacute;c nhận tham gia sự kiện.
                </p>
                <p>
                    &middot; &nbsp; &nbsp; &nbsp;Người chơi x&aacute;c nhận tham
                    gia sự kiện sẽ nhận được một tin nhắn x&aacute;c nhận (SMS)
                    trong v&ograve;ng 24 giờ kể từ thời điểm x&aacute;c nhận qua
                    tổng đ&agrave;i.
                </p>
                <p>
                    6. Đối tượng được tham gia: Tất cả c&aacute;c
                    kh&aacute;ch h&agrave;ng từ 18 tuổi trở l&ecirc;n, tham gia
                    chương tr&igrave;nh trong thời gian từ 15/05/2023 đến
                    31/05/2023
                </p>
                <p>&nbsp;</p>
                <p>
                    7. Giải thưởng: 01 v&eacute; tham dự sự kiện
                    TH&Iacute;CH TH&Igrave; NH&Iacute;CH diễn ra tại
                    th&agrave;nh phố H&agrave; Nội
                </p>
                <p>&nbsp;</p>
                <p>
                    8. C&aacute;ch thức x&aacute;c
                    định tr&uacute;ng thưởng:
                </p>
                <p>
                    Hệ thống sẽ mở ra cho kh&aacute;ch h&agrave;ng qu&eacute;t
                    m&atilde; QR từ 00:00 ph&uacute;t ng&agrave;y 15/05/2023 đến
                    23:59:59 ng&agrave;y 31/05/2023.
                </p>
                <p>
                    &middot; &nbsp; &nbsp; &nbsp; Website sẽ tự động quay
                    v&ograve;ng quay may mắn v&agrave; chọn người tr&uacute;ng
                    giải ngẫu nhi&ecirc;n.
                </p>
                <p>
                    &middot; &nbsp; &nbsp; &nbsp; Hệ thống sẽ th&ocirc;ng
                    b&aacute;o người chơi c&oacute; may mắn đăng k&yacute;
                    v&eacute; th&agrave;nh c&ocirc;ng hay kh&ocirc;ng.
                </p>
                <p>
                    &middot; &nbsp; &nbsp; &nbsp; Nếu người chơi được
                    th&ocirc;ng b&aacute;o đăng k&yacute; th&agrave;nh
                    c&ocirc;ng, Ban Tổ Chức chương tr&igrave;nh sẽ li&ecirc;n hệ
                    với người chơi th&ocirc;ng qua th&ocirc;ng tin c&aacute;
                    nh&acirc;n được cung cấp để x&aacute;c nhận tham gia sự
                    kiện.
                </p>
                <p>
                    &middot; &nbsp; &nbsp; &nbsp; Người chơi x&aacute;c nhận
                    tham gia sự kiện sẽ nhận được một tin nhắn x&aacute;c nhận
                    (SMS) trong v&ograve;ng 24 giờ kể từ thời điểm x&aacute;c
                    nhận qua tổng đ&agrave;i.
                </p>
                <p>&nbsp;</p>
                <p>
                    9. Đầu mối giải đ&aacute;p thắc mắc cho
                    kh&aacute;ch h&agrave;ng về c&aacute;c vấn đề li&ecirc;n
                    quan đến chương tr&igrave;nh:
                </p>
                <p>
                    Mọi thắc mắc hoặc th&ocirc;ng tin đến chương tr&igrave;nh,
                    kh&aacute;ch h&agrave;ng li&ecirc;n hệ:
                </p>
                <p>
                    Tổng đ&agrave;i: 0909465231 (từ 08:00 đến 19:00, thứ 2 đến
                    Chủ Nhật h&agrave;ng tuần, cước ph&iacute; theo quy định của
                    nh&agrave; mạng)
                </p>
                <p>Email: uyen.nguyen@focusmarketing.vn</p>
                <p>&nbsp;</p>
                <p>10. &nbsp; Quy định về v&eacute;:</p>
                <ul>
                    <li>
                        <p>
                            V&eacute; hợp lệ được gửi từ tổng đ&agrave;i của
                            chương tr&igrave;nh th&ocirc;ng qua hệ thống SMS,
                            được sử dụng cho 01 lượt tham gia tại sự kiện
                            TH&Iacute;CH TH&Igrave; NH&Iacute;CH diễn ra tại
                            th&agrave;nh phố H&agrave; Nội
                        </p>
                    </li>
                    <li>
                        <p>
                            Mỗi v&eacute; sẽ d&agrave;nh cho 01 người, mỗi
                            kh&aacute;ch h&agrave;ng chỉ được nhận tối đa 01
                            v&eacute;/SĐT:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    Kh&aacute;ch h&agrave;ng tự chịu chi
                                    ph&iacute; di chuyển đến địa điểm diễn ra sự
                                    kiện.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Kh&aacute;ch h&agrave;ng phải từ 18 tuổi trở
                                    l&ecirc;n.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Kh&aacute;ch h&agrave;ng phải mang giấy tờ
                                    tuỳ th&acirc;n (Căn cước c&ocirc;ng
                                    d&acirc;n hoặc Hộ chiếu hoặc Bằng l&aacute;i
                                    xe) để chứng thực th&ocirc;ng tin.
                                </p>
                            </li>
                        </ul>
                    </li>
                </ul>
                <p><br /></p>
                <p>
                    *Giải thưởng kh&ocirc;ng c&oacute; gi&aacute; trị quy đổi
                    th&agrave;nh tiền mặt.
                </p>
                <p>
                    *Trường hợp c&oacute; xảy ra tranh chấp, quyết định cuối
                    c&ugrave;ng thuộc về BTC.
                </p>
            </div>
        </div>
    </StandardLayout>
</template>

<style lang="scss" scoped>
.body-container {
    background-color: #0c150d;
    color: #fff;
    .page-container {
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        padding: 40px 30px;
        font-size: 3.5vw;
        @media (min-width: 769px) {
            font-size: 20px;
            padding-bottom: 50px;
        }
        p {
            margin-bottom: 15px;
        }
        ol {
            list-style: decimal;
            padding-left: 20px;
            li {
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 5px;
            }
            .lower-latin {
                list-style: lower-latin;
            }
            .disc-list {
                list-style: disc;
            }
            .circle-list {
                list-style: circle;
            }
            ul {
                margin-left: 20px;
            }
        }
    }

    .header {
        padding: 0 30px;
        font-size: 3vw;
        padding: 40px 30px 30px 30px;
        padding-bottom: 50px;
        line-height: normal;
        h1 {
            font-style: italic;
            font-size: 6vw;
            margin-bottom: 20px;
        }
        @media (min-width: 769px) {
            font-size: 20px;
            padding-bottom: 70px;
            h1 {
                font-size: 45px;
            }
        }
    }
}
</style>
