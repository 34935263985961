<script setup>
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import * as MSAL from "@azure/msal-browser";
import { refreshMsalToken, useLocalStorage } from "@tools/app";
import { usePage } from "@inertiajs/inertia-vue3";
import { Inertia } from "@inertiajs/inertia";
import { useI18n } from "vue-i18n";
import { LOCAL_STORAGE_ACCOUNT_ID_KEY_NAME } from "@constants/localStorage";
import { ACTION_SIGNUP, ACTION_LOGOUT } from "@constants/main";
import { storageAuth, getMission } from "@tools/app";
import azureConfig from "@constants/configs/default";

defineProps({
});

const { t } = useI18n();
const $store = useStore();
const $page = usePage();
const $localStorage = useLocalStorage();
const showModalLogout = ref(false);
const showModalGreeting = ref(false);
const showModalPrivateModeWarning = ref(false);

// let azureSettings = {};
// switch ($page.props?.value?.azure_settings?.env) {
//   case "development":
//     azureSettings = { ...azureConfig["development"] };
//     break;
//   case "testing":
//     azureSettings = { ...azureConfig["testing"] };
//     break;
//   case "production":
//   default:
//     azureSettings = { ...azureConfig["production"] };
//     break;
// }
const azureAdmin = {
  clientId: $page.props?.value?.azure_settings?.clientId,
  tenantName: $page.props?.value?.azure_settings?.tenantName,
  policyNames: {
    signUpSignIn: $page.props?.value?.azure_settings?.signupsignIn,
    forgotPassword: $page.props?.value?.azure_settings?.forgotPassword,
  },
  policyBaseUrl: $page.props?.value?.azure_settings?.policybaseurl,
};

$store.commit("setAzureConfig", azureAdmin);
const initAzure = () => {
  if ($store.getters.isAppReady) return;
  const msalConfig = {
    auth: {
      clientId: $store.state.app.azure.config.clientId,
      authority:
        $store.state.app.azure.policyAuthorities.signUpSignIn.authority,
      knownAuthorities: [
        ...Object.values(
          $store.state.app.azure.policyAuthorities.signUpSignIn.authority
        ),
        $store.state.app.azure.policyAuthorities.editProfile.authority
      ],
      postLogoutRedirectUri: route("ocp.landing"),
      navigateToLoginRequestUrl: false,
      redirectUri: route("member.redirect"),
    },
    cache: {
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
      cacheLocation: "localStorage",
    },
    cacheLocation: "localStorage",
  };
  const msal = new MSAL.PublicClientApplication(msalConfig);
  window.msal = msal;
  $store.dispatch("setMsal", msal);
  $store.dispatch("setApiAuth", $page?.props?.value?.api_data);
  $store.dispatch("appReady", true);
  storageAuth(msal, $page?.props?.value?.api_data);
};
const onAppInit = () => {
  initAzure();

  // case user visit via scan qr code (GRB flow)
  const code = $page.props?.value?.code;
  if (code) {
    // store code to local storage for the next time handle
    $localStorage.set("qrCode", code);
    $localStorage.set("qrCodeUrl", `${window.location.origin}/rl/${code}`);
  }

  const accountId = $localStorage.get(LOCAL_STORAGE_ACCOUNT_ID_KEY_NAME);
  let account = null;

  account = msal.getAccountByHomeId(accountId);
  if (account) {
    $store.dispatch("msalAccount", {
      account,
    });
    $store.dispatch("getMemberInfo", account);
  }
  
  if ($page.props?.value?.requireAuth ) {
    const currentMission = getMission($page.props.value.mission)

    if (!currentMission) {
      !account && Inertia.visit(route('member.register', { mission: currentMission?.alias || '' }))
    } else {
      // need update after implement edit profile
      if (account?.idTokenClaims?.[currentMission?.checkKey] !== 'true') {
        if(currentMission?.alias === 'real-leaf') {
          Inertia.visit(route('realLeaf.landing'))
        } else if(currentMission?.alias === 'coke-zero') {
          window.location.href = `${window.location.origin}/zs/landing/index.html`
        } else {
          Inertia.visit(route('member.register', { mission: currentMission?.alias || '' }))
        }
      }
    }
  }
};
onAppInit();

onMounted(() => {
  if ($localStorage.get("action") === ACTION_SIGNUP) {
    showModalGreeting.value = true;
  } else if ($localStorage.get("action") === ACTION_LOGOUT) {
    showModalLogout.value = true;
  }
  $localStorage.remove("action");
  // if (!$localStorage.get("firstTime") && $page.props?.value?.showPrivateModeWarning) {
  //   showModalPrivateModeWarning.value = true
  //   $localStorage.set("firstTime", true)
  // }
});
</script>

<template>
  <div class="flex flex-col w-full min-h-screen bg-red-01 text-primary-02 font-tccc bg-image">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
</style>
