<script setup>
import { onMounted, onUnmounted, ref, reactive, computed } from 'vue';
import Icons from './Icons.vue';

defineProps(['value', 'isError', 'errorMsg']);
const emits = defineEmits(['update:value']);

const countries = reactive([
    {
        key: 'hk',
        flag: './images/flag-hk.png',
        flagActive: './images/flag-hk-circle.png',
        code: '+852',
        name: 'Hongkong'
    },
    {
        key: 'tw',
        flag: './images/flag-tw.png',
        flagActive: './images/flag-tw-circle.png',
        code: '+886',
        name: 'Taiwan'
    },
])

const open = ref(false)
const active = ref('hk')
const phoneNumber = ref('')

const activeItem = computed(() => {
    const item = countries.find(item => item.key === active.value)
    return item || countries[0]
})

const closeOnEscape = (e) => {
    if (open.value && e.key === 'Escape') {
        open.value = false;
    }
};

const onUpdateValue = () => {
    const phone = `${activeItem.value.code}${phoneNumber.value[0] == '0' ? phoneNumber.value.substring(1) : phoneNumber.value}`
    open.value = false
    emits('update:valueFragment', { code: activeItem.value.code, number: phoneNumber.value })
    emits('update:value', phone)
}

onMounted(() => {
    document.addEventListener('keydown', closeOnEscape)
});
onUnmounted(() => document.removeEventListener('keydown', closeOnEscape));
</script>

<style scoped lang="scss">
.options {
    border-radius: 8px;
    filter: drop-shadow(0px 0px 6px rgba(255, 255, 255, 0.4));
    overflow: hidden;
    color: #969696;
}
</style>

<template>
    <div>
        <label class="text-xs font-medium">Enter Mobile Number*</label>
        <div class="input-tel relative flex border-b border-white" :class="isError ? 'border-error' : 'border-white'">
            <!-- Full Screen Dropdown Overlay -->
            <div v-show="open" class="fixed inset-0 z-40" @click="open = false"></div>
            <div class="country-code z-50">
                <div class="trigger w-full flex items-center py-1" @click="open = !open">
                    <span class="flag"><img :src="activeItem.flagActive" alt=""></span>
                    <span class="code ml-2">{{ activeItem.code }}</span>
                    <Icons icon="arrow-down" class="ml-4 w-5" />
                </div>
                <transition enter-active-class="transition ease-out duration-200"
                    enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <div v-show="open" class="options bg-gray absolute z-50 top-full left-0 w-full mt-1">
                        <div v-for="item in countries" class="item flex items-center p-3"
                            :class="[{ 'bg-gray-dark': item.key === active }, { 'text-lg': item.key === active }, { 'font-medium': item.key === active }, { 'text-white': item.key === active }]"
                            :key="item.key" @click="active = item.key; onUpdateValue()">
                            <img class="flag" :src="item.flag">
                            <span class="code ml-2">{{ item.code }}</span>
                            <span class="name ml-2">{{ item.name }}</span>
                        </div>
                    </div>
                </transition>
            </div>
            <input type="tel" class="phone-number border-0 bg-transparent focus:outline-0 flex-1 py-0"
                v-model="phoneNumber" @input="onUpdateValue" />
        </div>
        <div v-if="isError && errorMsg" class="text-xs text-error mt-1">{{ errorMsg }}</div>
    </div>
</template>
