<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import { Inertia } from '@inertiajs/inertia'
import StandardLayout from '@/Layouts/StandardLayout.vue';
import Button from '@/Components/Button.vue';
import InputTel from '@/Components/InputTel.vue';
import Input from '@/Components/Input.vue';
import { ref, computed, reactive } from 'vue'
import useValidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

const cooldownTime = 60
const currentCooldown = ref(0)
const disableResend = ref(false)
const sent = ref(false)

const resendText = computed(() => {
    const cooldown = ` (${currentCooldown.value}s)`
    return sent.value ? `Resend verification code${currentCooldown.value ? cooldown : ''} ` : `Send verification code`
})
const resend = () => {
    sent.value = true
    disableResend.value = true
    currentCooldown.value = cooldownTime
    const itv = setInterval(() => {
        currentCooldown.value--
        if (currentCooldown.value <= 0) {
            clearInterval(itv)
            disableResend.value = false
        }
    }, 1000);
}

const updatePhone = (value) => {
    state.phone.code = value.code
    state.phone.number = value.number
}

const state = reactive({
    phone: {
        code: '',
        number: ''
    }, 
    code: ''
})
const rules = computed(() => {
    return {
        phone: {
            number: { 
                required: helpers.withMessage('This field cannot be empty', required),
            }
        },
        code: {required: helpers.withMessage('This field cannot be empty', required)}
    }
})
const v$ = useValidate(rules, state)
const errors = computed(() => {
    return {
        number: v$.value?.phone?.number?.$errors[0]?.$message || '',
        code: v$.value?.code?.$errors[0]?.$message || ''
    }
})

const submit = () => {
    v$.value.$validate()
    !v$.value.$invalid && Inertia.visit(route('register.basicInfo'))
}

</script>
<style scoped>
</style>
<template>
    <Head title="Register" />
    <StandardLayout :showBack="true">
        <h1 class="font-tccc-head text-2xl font-bold">Sign Up</h1>
        <p class="text-lg font-medium mt-1">Verify your mobile number with verification code</p>
        <p class="font-tccc-unity text-xs mt-1">* Required</p>
        <div class="w-full mt-auto mb-auto">
            <InputTel @update:valueFragment="updatePhone" :isError="v$.phone?.number?.$error" :errorMsg="errors.number" />
            <Input class="mt-4" label="SMS verification code*" placeholder="Enter Password" :isError="v$.code.$error" :errorMsg="errors.code" v-model="state.code" />
            <Button type="primary-sm" class="w-full mt-8" @click="resend" :disabled="disableResend">{{resendText}}</Button>
            <Button type="primary-sm" class="w-full mt-4" @click="submit">Verify and continue</Button>
        </div>
        <p class="mt-auto mb-10">Didn’t receive the verification code via SMS?<br> <span class="font-bold underline">Re-enter the phone number</span></p>
    </StandardLayout>
</template>
