<script setup>
import { Head } from '@inertiajs/inertia-vue3';
import { Inertia } from '@inertiajs/inertia'
import StandardLayout from '@/Layouts/StandardLayout.vue';
import Button from '@/Components/Button.vue';
import InputTel from '@/Components/InputTel.vue';
import { reactive, computed } from 'vue';
import useValidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

const phone = reactive({
    code: '',
    number: ''
})

const rules = computed(() => {
    return {
        number: { 
            required: helpers.withMessage('This field cannot be empty', required),
        }
    }
})
const v$ = useValidate(rules, phone)
const errors = computed(() => {
    return {
        number: v$.value?.number?.$errors[0]?.$message || ''
    }
})

const updatePhone = (value) => {
    phone.code = value.code
    phone.number = value.number
}

const submit = () => {
    v$.value.$validate()
    !v$.value.$invalid && Inertia.visit(route('register.phoneVerify'))
}
</script>
<style scoped>
</style>
<template>

    <Head title="Register" />
    <StandardLayout :showBack="true">
        <h1 class="font-tccc-head text-2xl font-bold">Sign Up</h1>
        <p class="text-lg font-medium mt-1">Enter your mobile number</p>
        <p class="font-tccc-unity text-xs mt-1">* Required</p>
        <div class="w-full mt-auto mb-auto">
            <InputTel @update:valueFragment="updatePhone" :isError="v$.number.$error" :errorMsg="errors.number" />
            <Button class="w-full mt-8" @click="submit">Next</Button>
        </div>
    </StandardLayout>
</template>
