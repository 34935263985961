<script setup>
import * as MSAL from "@azure/msal-browser";
import { ref, onMounted } from "vue";
import Button from "@/Components/Button.vue"

const msal = ref(null);
const initAzure = () => {
  const msalConfig = {
    auth: {
      clientId: "a99e0cd0-054c-4b0d-84c5-8aab1b1d5160",
      authority:
        "https://apacbeta.b2clogin.com/apacbeta.onmicrosoft.com/b2c_1a_signup_signin_cokezero_beta_ocp_tw",
      knownAuthorities: [
        "https://apacbeta.b2clogin.com/apacbeta.onmicrosoft.com/b2c_1a_signup_signin_cokezero_beta_ocp_tw",
      ],
      postLogoutRedirectUri: "https://test.cokeplus.tw",
      navigateToLoginRequestUrl: false,
      redirectUri: "https://test.cokeplus.tw/redirect",
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };
  msal.value = new MSAL.PublicClientApplication(msalConfig);
};

const login = () => {
  sessionStorage.removeItem("msal.interaction.status");
  msal.value.loginRedirect({
    authority: "https://apacbeta.b2clogin.com/apacbeta.onmicrosoft.com/b2c_1a_signup_signin_cokezero_beta_ocp_tw",
    extraQueryParameters: {},
  });
};

const logout = () => {
  sessionStorage.removeItem("msal.interaction.status");
  msal.value.logout({
    postLogoutRedirectUri: "https://test.cokeplus.tw",
  });
};

onMounted(() => {
  initAzure();
});
</script>

<template>
<Button type="secondary" class="w-48 mt-4" @click="login">login</Button>
<Button type="secondary" class="w-48 mt-4" @click="logout">logout</Button>
</template>

<style scoped lang="scss">
</style>
