<template>
  <App>
    <div
      class="ocp-head flex flex-nowrap justify-between p-4 md:py-8 items-center z-20 hidden"
      :class="`${$props.theme}`"
    >
      <slot name="topBar" />
    </div>
    <div class="ocp-page">
      <slot />
    </div>
    <div
      v-if="isLoading"
      class="min-h-screen w-full flex justify-center items-center bg-primary-01 fixed z-50"
    >
      <picture class="block w-20">
        <!-- <img src="/images/loading.png" alt="" class="w-full" /> -->
        <div class="lds-ripple"><div></div><div></div></div>
      </picture>
    </div>

    <!-- <Footer :theme="$prop.theme" /> -->
  </App>
</template>

<script setup>
import { Link } from "@inertiajs/inertia-vue3";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import Button from "@/Components/Button.vue";
import Footer from "@/Components/Footer.vue";
import NavMenu from "@/Components/NavMenu.vue";
import App from "./App.vue";

const { t } = useI18n();
const $prop = defineProps({
  showBack: {
    type: Boolean,
    default: false,
  },
  showNav: {
    type: Boolean,
    default: true,
  },
  theme: {
    type: String,
    default: "",
  },
  headTitle: {
    type: String,
    default: "",
  },
  desc: {
    type: String,
    default: "",
  },
  isLoading: {
    type: [Boolean, Number],
    default: false,
  },
});

const menuStatus = ref(false);

const emit = defineEmits(["clickBack"]);

const back = () => {
  if (
    window.history.state.component === "Scan" ||
    window.history.state.component === "Auth/MyProfile"
  ) {
    emit("clickBack");
  } else {
    window.history.back();
  }
};

const hideBack = computed(() => {
  return route().params.back === "hide";
});
const hideNav = computed(() => {
  return route().params.nav === "hide";
});

const navBarStyle = computed(() => {
  return $prop.headTitle;
});

const headStyle = computed(() => {
  switch ($prop.theme) {
    case "main":
      return "text-center md:text-right pt-3";
      break;
    default:
      return "text-right";
  }
});
const bgDesktop = computed(() => {
  switch ($prop.theme) {
    case "main":
      return "/images/landing/bg-landing-desktop.png";
      break;
    default:
      return "/images/landing/bg-desktop.png";
  }
});
</script>

<style scoped lang="scss">
.image-bg {
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  height: 100%;
}

.ocp-head {
  flex: 0 0 auto;
  min-height: 35px;
  top: 0;
  left: 0;
  width: 100%;
  //position: relative;
  //z-index: 99;
  @media (min-width: 768px) {
    padding-left: calc(61 / 1920 * 100vw);
    padding-right: calc(61 / 1920 * 100vw);
  }
}

.logo {
  width: calc(140 / 750 * min(100vw, 768px));
  height: calc(140 / 750 * min(100vw, 768px));
  @media (min-width: 768px) {
    width: calc(140 / 1920 * 100vw);
    height: calc(140 / 1920 * 100vw);
  }
}

.slogan {
  font-size: calc(42 / 750 * min(100vw, 768px));
  line-height: 1.1;
  margin-top: 0;
  position: absolute;
  text-align: left;
  top: 2.6rem;
  text-decoration: none;
  left: calc(180 / 750 * min(100vw, 768px));
  @media (min-width: 768px) {
    font-size: calc(65 / 1920 * min(100vw));
    text-align: left;
    margin: 0;
    position: static;
  }
  .main & {
    margin-top: calc(80 / 750 * min(100vw, 768px));
    font-size: calc(40 / 750 * min(100vw, 768px));
    top: auto;
    left: auto;
    position: static;
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
      font-size: calc(65 / 1920 * min(100vw));
      margin-top: 0;
    }
  }
}

.title {
  font-size: calc(50 / 750 * min(100vw, 768px));
  line-height: 1.2;
  margin-top: calc(120 / 750 * min(100vw, 768px));
  @media (min-width: 768px) {
    margin-top: 0;
    font-size: calc(45 / 1920 * 100vw);
  }
  .main & {
    margin-top: 10px;
    @media (min-width: 768px) {
      margin-top: 0px;
    }
  }
}

.desc {
  font-size: calc(22 / 750 * min(100vw, 768px));
  @media (min-width: 768px) {
    font-size: 35px;
  }
}

.menu-nav {
  top: calc(30 / 750 * min(100vw, 768px));
  right: calc(30 / 750 * min(100vw, 768px));
  @media (min-width: 768px) {
    position: relative;
    top: auto;
    right: auto;
    margin-left: calc(30 / 1920 * 100vw);
  }
}
</style>
