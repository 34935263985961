<script setup>
import { ref, computed } from 'vue';
import Icons from '@/Components/Icons.vue';

const prop = defineProps({
  select: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  isError: {
    type: Boolean,
    default: false
  },
  errorMsg: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: []
  },
  defaultKeyOption: {
    type: String,
    default: ''
  }
});

defineEmits(['selectOption']);

const placeholderSelect = ref('')
const open = ref(false)
const input = ref(null);


const update = (event, option) => {
  placeholderSelect.value = option.text
}

// const defaultIcon = computed(() => prop.options[defaultOpt.value]?.icon ? prop.options[defaultOpt.value].icon : '')
const defaultText = computed(() => prop.options.forEach(option => option.key === defaultKeyOption ? option.text : ''))

</script>

<template>
  <div class="relative">
    <label v-if="label" class="text-xs font-md">{{ label }}</label>

    <div @click="open = !open" class="toggle relative">
      <!-- <span v-if="defaultOpt > -1">
        <i class="icon" :class="defaultIcon"></i> {{ defaultText }}
      </span> -->
      <span class="text">{{ placeholderSelect || 'Select' }}</span>
      <Icons icon="arrow-down" class="icon absolute right-0" />
    </div>

    <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">

      <ul @click="open = false" :class="{ active: open }" v-show="open"
        class="bg-gray absolute z-50 top-full left-0 w-full mt-1">

        <li v-for="option in prop.options" :key="option.key" class="item flex items-center p-3"
          @click="update(event, option); $emit('selectOption', option)">

          <i class="icon" :class="option.icon" v-if="option.icon"></i>
          <span class="text" v-if="option.text">{{ option.text }}</span>
        </li>
      </ul>
    </transition>
    <div v-if="isError && errorMsg" class="text-xs text-error mt-1">{{ errorMsg }}</div>
  </div>
</template>

<style scoped lang="scss">
.icon {
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 20px;
}

.toggle {
  border-bottom: 1px solid #fff;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 33px;
}


ul {
  border-radius: 8px;
  filter: drop-shadow(0px 0px 6px rgba(255, 255, 255, 0.4));
  overflow: hidden;
  color: #969696;
  list-style: none;
  overflow: hidden;

  &.active {
    opacity: 1;
    height: auto;
  }
}

li {
  background: #fff;
  color: #000;
  text-align: right;

  &.selected {
    background: #ccc;
  }
}
</style>