import './bootstrap';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { createStore } from 'vuex'
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'
import moduleApp from '@store/app'
import moduleAuth from '@store/auth'
import { createI18n } from 'vue-i18n'
import * as enGB from '@/locales/en.json'
import * as vnJson from '@/locales/vn.json'
// import * as TH from '@/locales/th.json'
import { GOOGLE_RECAPTCHA_KEY_PROD, GOOGLE_RECAPTCHA_KEY_BETA } from '@constants/main';

const appName = window.document.getElementsByTagName('title')[0]?.innerText;
const store = createStore({
    modules: {
        app: moduleApp,
        auth: moduleAuth,
    }
})

const i18n = createI18n({
    locale: 'vn',
    allowComposition: true, // you need to specify that!
    warnHtmlInMessage: 'off',
    legacy: false,
    messages: {
        vn: vnJson,
    }
})
window.$store = store
store.commit('setI18n', i18n)

// const keyCaptcha = window.location.origin === 'https://qa.musicbreaks.coca-cola.com.sg' ? GOOGLE_RECAPTCHA_KEY_BETA : GOOGLE_RECAPTCHA_KEY_PROD
// const keyCaptchaEnv =  document.querySelector('html').getAttribute('data-ggrc')
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => require(`./Pages/${name}.vue`),
    setup({ el, app, props, plugin }) {
        return createApp({ render: () => h(app, props) })
            .use(plugin)
            .use(store)
            .use(i18n)
            .mixin({ methods: { route } })
            .mount(el)
    },
});

// Set azure config for create MSAL instance.
InertiaProgress.init({ color: '#4B5563' });
