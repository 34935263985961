<script setup>
import { computed } from "vue";
const props = defineProps({
  type: {
    type: String,
    default: "primary",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const classes = computed(() => {
  switch (props.type) {
    case "primary":
    default:
      return `font-[700] text-center`;
    case "primary-sm":
      return `bg-white border border-transparent font-bold text-xl text-black h-10`;
    case "primary-sm-disabled":
      return `bg-secondary-04 border border-transparent font-bold text-xl text-secondary-05 h-10 pointer-events-none`;
    case "secondary":
      return `bg-transparent border-2 border-primary-01 font-bold text-xl text-primary-01 h-60px`;
    case "secondary-sm":
      return `bg-transparent border-2 border-primary-01 font-bold text-xl text-primary-01 h-10`;
    case "secondary-dark":
      return `bg-transparent border-2 border-primary-02 font-bold text-xl text-primary-02 h-60px`;
    case "secondary-dark-sm":
      return `bg-transparent border-2 border-primary-02 font-bold text-xl text-primary-02 h-10`;
    case "cta":
      return `relative ocp-cta-circle font-bold cursor-pointer rounded-full`;
    case "circle-nav":
      return `ocp-circle-nav rounded-full`;
  }
});
</script>

<style scoped lang="scss">
.btn-primary {
  background: #36fd00;
  text-transform: uppercase;
  color: #0f1610;
  text-decoration: none;
  transition: 0.2s;
  padding: 3vw 8vw;
  font-size: 4vw;
  font-style: italic;
  @media (min-width: 769px) {
    padding: 15px 30px;
    min-width: 300px;
    font-size: 20px;
  }

  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    background-color: #939393;
    color: rgba(255, 255, 255, 0.5);
  }
}

.btn-primary-sm {
  height: 40px;

  &:disabled {
    background-color: #939393;
    color: rgba(255, 255, 255, 0.5);
  }
}

.btn-secondary-dark,
.btn-secondary-dark-sm {
  &:disabled {
    color: #939393;
    border-color: #939393;
  }
}

/* button active state */
.ocp-circle-nav {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  background-color: transparent;
  font: inherit;
  font-size: 0;
  text-indent: -9999px;
  border-radius: 50%;
  transition: background 0.3s;
  cursor: pointer;
  border: none;
  appearance: none;
  box-shadow: none;
  width: calc(86 / 750 * min(100vw, 768px));
  height: calc(86 / 750 * min(100vw, 768px));
  @media (min-width: 768px) {
    width: calc(86 / 1920 * 100vw);
    height: calc(86 / 1920 * 100vw);
  }
  &:hover {
    outline: none;
    box-shadow: 0 0 10px #666;
    span {
      background-color: #e61d2b;
      &::before,
      &::after {
        background-color: #e61d2b;
      }
    }
    &.is-active {
      box-shadow: 0 0 10px #fff;
      background-color: #e61d2b;
      span {
        &::before,
        &::after {
          background-color: #fff;
        }
      }
    }
  }
  &:focus {
    outline: none;
    span {
      background-color: #fff;
      &::before,
      &::after {
        background-color: #fff;
      }
    }
  }
  span {
    display: block;
    position: absolute;
    background: #e61d2b;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background 0.3s;
    height: calc(8 / 750 * min(100vw, 768px));
    width: calc(61 / 750 * min(100vw, 768px));
    @media (min-width: 768px) {
      width: calc(60 / 1920 * 100vw);
      height: calc(10 / 1920 * 100vw);
    }
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      background: #e61d2b;
      border-radius: 20px;
      left: 0;
      width: 100%;
      transition-duration: 0.3s, 0.3s;
      transition-delay: 0.3s, 0s;
      transition-property: top, transform;
      height: 100%;
      bottom: 180%;
    }
    &::after {
      transition-property: bottom, transform;
      top: 180%;
      bottom: auto;
    }
  }
}
.ocp-circle-nav.is-active {
  outline: none;
  background-color: transparent;
  span {
    background: none;
    &::before {
      top: 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition-delay: 0s, 0.3s;
      transition-delay: 0s, 0.3s;
      background: #fff;
    }
    &::after {
      background: #fff;
      top: 0;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transition-delay: 0s, 0.3s;
      transition-delay: 0s, 0.3s;
    }
  }
}

.ocp-cta-circle {
  width: calc(280 / 750 * min(100vw, 768px));
  height: calc(280 / 750 * min(100vw, 768px));
  font-size: calc(26 / 750 * min(100vw, 768px));
  border: calc(20 / 750 * min(100vw, 768px)) solid #fff;
  @media (min-width: 1024px) {
    width: 200px;
    height: 200px;
    font-size: 19px;
    border-width: 16px;
  }
  &:disabled div {
    background-color: #939393 !important;
    color: rgba(255, 255, 255, 0.5) !important;
  }
  &::before,
  &::after {
    content: "";
    display: block;
    height: 45%;
    width: 45%;
    border-radius: 50%;
    background: #b10019;
    margin: 10px;
    position: absolute;
    transition: 5s ease;
    z-index: -1;
  }

  &:not(:disabled):hover {
    -webkit-animation: bloop 1s linear;
    animation: bloop 1s linear;
    &::before {
      -webkit-animation: waves 2.5s linear;
      animation: waves 2.5s linear;
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
    }
    &::after {
      -webkit-animation: waves 2.5s linear 0.7s forwards;
      animation: waves 2.5s linear 0.7s forwards;
    }
  }
}

.btn-cta-main {
  &.pos-bottom {
    position: absolute;
    left: 50%;
    bottom: calc(160 / 750 * min(100vw, 768px));
    transform: translateX(-50%);
  }
}

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}
@keyframes bloop {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.2, 0.8, 1);
  }
  40% {
    transform: scale3d(0.8, 1.2, 1);
  }
  50% {
    transform: scale3d(1.1, 0.9, 1);
  }
  65% {
    transform: scale3d(0.98, 1.02, 1);
  }
  75% {
    transform: scale3d(1.08, 0.98, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
</style>

<template>
  <button
    :type="type"
    class="text-center rounded-full focus:outline-none"
    :class="[classes, `btn-${type}`]"
    :disabled="disabled"
  >
    <div
      class="font-black z-10 bg-black text-white w-full h-full rounded-full flex justify-center items-center uppercase"
      v-if="type === 'cta'"
    >
      <slot />
    </div>
    <span v-if="type === 'circle-nav'"></span>
    <slot v-if="type !== 'cta'" />
  </button>
</template>
