<script setup>
import StandardLayout from "@/Layouts/StandardLayout.vue";
import { useI18n } from "vue-i18n";
import { ref, onMounted } from "vue";
import { Inertia } from "@inertiajs/inertia";
import { useLocalStorage } from "@tools/app";
import { isLogin } from "@tools/app";

const { t } = useI18n();
const loading = ref(true);
const $localStorage = useLocalStorage();

const logout = async () => {
    try {
        sessionStorage.removeItem("msal.interaction.status");
        await $store.dispatch("logout", { msal: $store.state.app.msal, route });
    } catch (error) {
        console.log(error);
    } finally {
        Inertia.visit(route("ocp.landing"));
    }
};

onMounted( () => {
  if ($localStorage.get("USER_WIN") && isLogin()) {
    loading.value = false;
  } else {
    logout()
  }
});
</script>

<template>
  <StandardLayout :isLoading="loading">
    <div
      class="body-container overflow-auto min-h-screen flex md:items-center font-barlow"
    >
      <div class="absolute top-0 z-0 w-full">
        <img src="/images/landing/bg-mobile.png" alt="bg" class="w-full md:hidden" />
        <img
          src="/images/landing/bg-desktop.png"
          alt="bg"
          class="w-full hidden md:block"
        />
      </div>

      <div class="page-container z-1 relative text-white text-center md:text-left">
        <div class="md:grid md:grid-cols-5 md:grid-rows-2">
          <div class="col-span-3 header">
            <h1 v-html="t('ty_win.heading')"></h1>
          </div>
          <div class="col-span-2 row-span-2">
            <img src="/images/thumsup.svg" alt="thumsup" class="mx-auto kol-icon" />
          </div>
          <div class="col-span-3 bottom-text">
            <p class="font-[500] desc">{{ t("ty_win.desc") }}</p>
            <p>
              <a href='https://www.facebook.com/events/575411984735127/' class="text-[#36FD00] text-center font-[500] link" target='_blank'>
                {{ t("ty_win.info") }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </StandardLayout>
</template>

<style lang="scss" scoped>
.body-container {
  background-color: #0c150d;
  color: #fff;
  .page-container {
    max-width: 1100px;
    margin: 0 auto;
    width: 100%;
    padding: 40px 30px;
    font-size: 3.5vw;
    @media (min-width: 769px) {
      font-size: 20px;
      padding-bottom: 50px;
    }
    p {
      margin-bottom: 15px;
    }
    .grid-rows-2 {
      grid-template-rows: repeat(2, minmax(0, 1fr));
    }
    .bottom-text {
      padding-top: 10vw;
      @media (min-width: 769px) {
        margin-bottom: 30px;
        padding-top: 0;
        margin-top: -60px;
        .desc {
          margin-bottom: 50px;
        }
      }
    }
  }

  .header {
    line-height: normal;
    h1 {
      font-style: italic;
      font-size: calc(46 / 750 * min(100vw, 768px));
      font-weight: 700;
      margin-bottom: 10vw;
      @media (min-width: 769px) {
        font-size: 42px;
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
  }
  p {
    font-size: calc(20 / 750 * min(100vw, 768px));
  }
  .header,
  .bottom-text {
    @media (min-width: 769px) {
      padding-right: 50px;
    }
  }
}
.kol-icon {
  max-width: calc(454 / 750 * min(100vw, 768px));
  margin: 0 auto;
}
.bottom-text {
    max-width: calc(580 / 750 * min(100vw, 768px));
    margin: 0 auto;
}
</style>
