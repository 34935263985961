<script setup>
import { Inertia } from "@inertiajs/inertia";
import { Link } from "@inertiajs/inertia-vue3";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import Button from "./Button.vue";
import { isLogin } from "@tools/app";

const { t } = useI18n();
const emit = defineEmits(["close", "logout"]);
const $prop = defineProps({
    theme: {
        type: String,
        default: "",
    },
    status: {
        type: Boolean,
        default: false,
    },
});
const navStyle = computed(() => {
    switch ($prop.theme) {
        case "main":
            return "";
            break;
        default:
            return "";
    }
});

const handleLogout = async() => {
    emit("logout")
    await logout()
}

const logout = async () => {
    try {
        // loading.value = true;
        sessionStorage.removeItem("msal.interaction.status");
        await $store.dispatch("logout", { msal: $store.state.app.msal, route });
    } catch (error) {
        console.log(error);
    } finally {
        // loading.value = false;
        Inertia.visit(route("ocp.landing"));
    }
};
</script>

<template>
    <nav
        class="fixed top-0 right-0 w-full h-full bg-red-04 text-white ocp-sidenav transition-all text-center md:text-left md:w-auto z-20 overflow-hidden"
        :class="[navStyle, { 'is-active': $props.status }]"
    >
        <img
            src="/images/sound-red1.svg"
            alt="coke"
            class="nav-bg-1 z-0 md:hidden"
        />
        <img
            src="/images/sound-red2.svg"
            alt="coke"
            class="nav-bg-2 z-0 md:hidden"
        />
        <img
            src="/images/sound-red3.svg"
            alt="coke"
            class="nav-bg-3 z-0 hidden md:block"
        />
        <div
            class="nav-inner flex justify-between items-center flex-col md:items-start md:justify-start overflow-hidden overflow-y-auto h-[100vh]"
        >
            <div
                class="nav-ctrl flex justify-between items-center w-full md:justify-end"
            >
                <div class="flex items-center md:hidden">
                    <Link :href="route('ocp.landing')" class="logo mr-3">
                        <img src="/images/logo2x.png" alt="coke" class="" />
                    </Link>
    
                    <Link :href="route('ocp.landing')" class="slogan">
                        <h2 class="font-black text-white relative" v-html="t('kpop_promo')">
                        </h2>
                    </Link>
                </div>
                <Button
                    type="circle-nav"
                    class="menu-close"
                    :class="{ 'is-active': true }"
                    @click="$emit('close')"
                />

                <!-- <Icons
                    class="menu-close"
                    icon="menu-close"
                    @click="$emit('close')"
                /> -->
            </div>
            <div
                class="nav-content flex justify-center items-center flex-col md:mt-5"
            >
                <p class="hidden md:block">
                    {{ t("nav.desc") }}
                </p>
                <ul
                    class="link-list flex flex-col justify-center items-center uppercase font-bold md:items-start w-full relative"
                >
                    <li>
                        <Link :href="route('ocp.landing')">
                            {{ t("nav.home") }}
                        </Link>
                    </li>
                    <li>
                        <Link :href="route('ocp.WinnerList')">
                            {{ t("nav.winner_list") }}
                        </Link>
                    </li>
                    <li>
                        <a  href="https://www.coca-cola.com.sg/policies/privacy-policy" target="_blank">
                            {{ t("nav.policy") }}
                        </a>
                    </li>
                    <li>
                        <Link :href="route('ocp.termsConditions')">
                            {{ t("nav.tnc") }}
                        </Link>
                    </li>
                    <li @click.once="handleLogout" class="cursor-pointer" v-if="isLogin()">
                        logout
                    </li>
                </ul>
            </div>
            <div></div>
        </div>
    </nav>
</template>

<style lang="scss" scoped>
.ocp-sidenav {
    transform: translateX(100%);
    box-shadow: 0 0 20px #666;
    @media (min-width: 768px) {
        max-width: calc(700 / 1920 * 100vw);
    }

    p {
        @media (min-width: 768px) {
            font-size: 22px;
            margin-bottom: 60px;
        }
    }

    &.is-active {
        transform: translateX(0);
    }
}
.nav-inner {
    padding: calc(30 / 750 * min(100vw, 768px))
        calc(33 / 750 * min(100vw, 768px)) calc(60 / 750 * min(100vw, 768px));
    @media (min-width: 768px) {
        padding: calc(33 / 1920 * 100vw) calc(76 / 1920 * 100vw) calc(60 / 1920 * 100vw) ;
    }
}
.logo {
    width: calc(140 / 750 * min(100vw, 768px));
    height: calc(140 / 750 * min(100vw, 768px));
    @media (min-width: 768px) {
        width: 140px;
        height: 140px;
    }
}

.menu-close {
    position: relative;
}

.link-list {
    font-size: calc(45 / 750 * min(100vw, 768px));
    @media (min-width: 768px) {
        font-size: calc(35 / 1935 * 100vw);
    }
    li {
        list-style: none;
        + li {
            margin-top: calc(140 / 750 * min(100vw, 768));
            @media (min-width: 768px) {
                margin-top: 60px;
            }
        }
    }
    a {
        text-decoration: none;
    }
}

.slogan {
    font-size: calc(42 / 750 * min(100vw, 768px));
    line-height: 1.1;
    margin-top: 0;
    text-align: left;
    text-decoration: none;
}
.nav-bg-1 {
    position: absolute;
    top: calc(-526 / 750 * min(100vw, 768px));
    left: calc(337 / 750 * min(100vw, 768px));
    width: calc(1064 / 750 * min(100vw, 768px));
    height: calc(1075 / 750 * min(100vw, 768px));
}
.nav-bg-2 {
    position: absolute;
    top: calc(847 / 750 * min(100vw, 768px));
    left: calc(-387 / 750 * min(100vw, 768px));
    width: calc(1223 / 750 * min(100vw, 768px));
    height: calc(1232 / 750 * min(100vw, 768px));
}
.nav-bg-3 {
    position: absolute;
    bottom: -110vh;
    left: 38%;
    width: calc(1591 / 1920 * 100vw);
    height: calc(1602 / 1920 * 100vw);
    transform: scale(1.8);
}
</style>
