<script setup>
import StandardLayout from "@/Layouts/StandardLayout.vue";
import { useI18n } from "vue-i18n";
import { ref } from "vue";

const { t } = useI18n();
</script>

<template>
  <StandardLayout>
    <div class="body-container overflow-auto min-h-screen font-barlow">
      <div class="absolute top-0 z-0 w-full">
        <img src="/images/landing/bg-mobile.png" alt="bg" class="w-full md:hidden" />
        <img
          src="/images/landing/bg-desktop.png"
          alt="bg"
          class="w-full hidden md:block"
        />
      </div>

      <div class="header text-right z-1 relative">
        <h1 v-html="t('privacy_policy_head')" class="font-[700]"></h1>
      </div>

      <div class="page-container z-1 relative text-[#C6C6C6]">
        <p>Sứ mệnh của chúng tôi là “Đổi mới thế giới và Làm nên sự khác biệt” và chúng tôi không thể làm điều đó nếu không có sự tin tưởng của các bạn, vì vậy chúng tôi cung cấp cho các bạn thông tin sau để thông báo chính xác dữ liệu chúng tôi thu thập từ bạn và cách chúng tôi sử dụng dữ liệu đó để cung cấp trải nghiệm tốt nhất và mới nhất có thể.</p>
        <p>Chính sách Bảo mật này mô tả các thông lệ của chúng tôi liên quan đến thông tin mà chúng tôi thu thập thông qua các hoạt động liên quan đến Chính sách Bảo mật này bao gồm các trang web (“Trang web” của chúng tôi) và bất kỳ trang web, ứng dụng, tiện ích di động nào và các tính năng tương tác di động khác (gọi chung là “Ứng dụng” của chúng tôi), thông qua các dịch vụ khác mà chúng tôi có thể cung cấp liên quan đến các Trang web và Ứng dụng của chúng tôi, như chương trình khuyến mại, chương trình thưởng, thông qua các trang truyền thông xã hội chính thức mà chúng tôi kiểm soát (“Trang Truyền thông Xã hội” của chúng tôi), cũng như qua email có định dạng HTML mà chúng tôi gửi cho các bạn (gọi chung là “Mạng”, bao gồm các Trang, Ứng dụng và Trang web trên mạng xã hội).</p>
        <p><u>Bằng cách cung cấp Thông tin Cá nhân cho chúng tôi, bạn đồng ý với các điều khoản và điều kiện của Chính sách Bảo mật này.</u></p>
        <div class="red-box">
          <p>Chúng tôi rất coi trọng quyền riêng tư, nhưng quan trọng hơn, chúng tôi coi trọng mối quan hệ của chúng tôi với bạn.</p>        
        </div>
        <h4 class="font-[500]">THÔNG TIN CÁ NH N</h4>
        <p>“Thông tin Cá nhân” là bất kỳ thông tin nào xác định bạn là một cá nhân và bất kỳ thông tin nào khác mà chúng tôi liên kết với thông tin đó. Chúng tôi thu thập một số loại thông tin, từ một số nguồn khác nhau:</p>
        <ol>
          <li>
            <p>Thông tin hồ sơ và nội dung khác mà bạn tự nguyện cung cấp cho chúng tôi, có thể bao gồm:</p>
            <ul class="disc-list">
              <li>Tên người dùng và mật khẩu;</li>
              <li>Thông tin liên hệ khi bạn đăng ký trên Trang web của chúng tôi, chẳng hạn như tên, địa chỉ, thông tin nhân khẩu học (chẳng hạn như giới tính của bạn), ngày tháng năm sinh, số điện thoại di động và nhà riêng, và/hoặc địa chỉ email.</li>
              <li>Bạn cũng có thể cung cấp cho chúng tôi thông tin về những người khác, ví dụ như khi bạn hướng dẫn chúng tôi gửi quà thay mặt bạn. Bạn hiểu rằng việc thu thập, sử dụng và/hoặc tiết lộ các thông tin liên quan đến cá nhân khác đều phải được thực hiện sau khi cá nhân đó đã đồng ý chấp thuận các điều kiện và điều khoản của Chính sách Bảo mật này. Chúng tôi có quyền yêu cầu bạn cung cấp bằng chứng của sự chấp thuận này tại bất kỳ thời điểm nào trong trường hợp quy định pháp luật có yêu cầu.</li>
              <li>Bất kỳ nội dung hoặc đóng góp nào bạn đăng công khai trên Mạng. Điều này bao gồm các nhận xét, video và ảnh mà bạn gửi. Nếu bạn liên hệ với chúng tôi thông qua một trang mạng xã hội, chúng tôi có thể thu thập mã nhận dạng mạng xã hội của bạn.</li>
              <li>Bất kỳ thông tin nào bạn cung cấp khi liên lạc với chúng tôi, qua mạng xã hội, e-mail hoặc trung tâm chăm sóc khách hàng của chúng tôi.</li>
              <li>Thông tin thanh toán, trong trường hợp khi bạn mua hàng của chúng tôi hoặc thông tin về các khoản chi trả mà chúng tôi cần thông tin thanh toán để xử lý giao dịch.</li>
            </ul>
          </li>
          <li>
            <p>Thông tin được thu thập thụ động khi bạn sử dụng Mạng, chẳng hạn như máy chủ của chúng tôi và các công cụ phân tích của bên thứ ba mà chúng tôi sử dụng:</p>
            <ul class="disc-list">
              <li>Nhật ký hệ thống của chúng tôi có thể ghi lại thông tin khách truy cập vào Mạng của chúng tôi, bao gồm yêu cầu web, địa chỉ Giao thức mạng (“IP”), mã nhận dạng trên thiết bị và thiết bị di động, thông tin trình duyệt, tương tác trên Mạng, các trang đã xem, sử dụng ứng dụng, và thông tin tương tự như vậy; chúng tôi có thể thu thập thông tin tương tự từ các email bạn nhận được từ chúng tôi, điều này có thể giúp chúng tôi theo dõi email nào được mở và liên kết nào được người nhận nhập vào.</li>
              <li>Chúng tôi sử dụng một số cookie, thẻ pixel và các công nghệ khác để giúp chúng tôi hiểu cách bạn sử dụng Mạng và cho phép chúng tôi cá nhân hóa trải nghiệm. Chúng tôi có thể yêu cầu các nhà quảng cáo hoặc các đối tác thứ ba khác phân phát quảng cáo hoặc dịch vụ đến thiết bị của bạn, dựa trên cookie hoặc các công nghệ tương tự được đặt trên Mạng của chúng tôi.</li>
              <li>Chúng tôi có thể phát hiện vị trí thiết bị, chẳng hạn bằng cách sử dụng thông tin GPS được thu thập bởi các ứng dụng di động hoặc thông tin vị trí được chia sẻ bởi trình duyệt web. Chúng tôi thu thập thông tin này với mục đích nâng cao hoặc hỗ trợ các dịch vụ của chúng tôi, chẳng hạn như kích hoạt một số chức năng nhất định của Mạng có thể cung cấp cho bạn thông tin về các chương trình khuyến mãi hoặc thông tin sản phẩm có liên quan gần bạn. Chúng tôi có thể sử dụng vị trí thực tế của thiết bị của bạn để hiểu rõ hơn về cách thức mà Mạng của chúng tôi cũng như các dịch vụ và chức năng khác đang được sử dụng và cung cấp cho bạn các dịch vụ và nội dung dựa trên vị trí có liên quan và được cá nhân hóa, bao gồm cả quảng cáo. Nếu bạn muốn chọn không tham gia thu thập thông tin này, vui lòng điều chỉnh cài đặt của thiết bị để thông tin định vị không được gửi cho chúng tôi hoặc các bên thứ ba bằng cách (a) tắt dịch vụ định vị trong cài đặt thiết bị; hoặc (b) từ chối một số trang web hoặc ứng dụng di động cho phép truy cập thông tin vị trí bằng cách thay đổi các tùy chọn và quyền có liên quan trong cài đặt trình duyệt hoặc thiết bị di động. Xin lưu ý rằng định vị của bạn có thể bắt nguồn từ các cài đặt WiFi, Bluetooth và các thiết bị khác của bạn. Vui lòng tham khảo cài đặt thiết bị của bạn để biết thêm thông tin.</li>
            </ul>
          </li>
          <li>Chúng tôi có thể nhận thông tin từ các bên thứ ba như các đối tác quảng cáo và tiếp thị, cơ sở dữ liệu công cộng và các nền tảng truyền thông xã hội, để bổ sung thông tin chúng tôi nhận được từ người tiêu dùng của mình. Nếu chúng tôi làm như vậy, chính sách này điều chỉnh cách chúng tôi có thể sử dụng thông tin đó.</li>
          <li>Ngoài việc thu thập Thông tin Cá nhân, chúng tôi có thể thu thập thông tin không nhận dạng và không liên quan đến Thông tin Cá nhân. Chúng tôi cũng có thể xóa danh tính hoặc ẩn danh thông tin để nó không còn nhận dạng nữa. Chúng tôi có thể tổng hợp và sử dụng thông tin đó để tham gia vào hoạt động tiếp thị và các hoạt động khác theo cách bảo vệ người tiêu dùng của chúng tôi vì nó không sử dụng Thông tin Cá nhân của họ và nằm ngoài phạm vi của Chính sách Bảo mật này.</li>          
        </ol>
        <p>Cách chúng tôi có thể sử dụng Thông tin Cá nhân</p>
        <p>Chúng tôi có thể sử dụng Thông tin Cá nhân khi được pháp luật cho phép, cho các mục đích kinh doanh sau:</p>
        <ul class="disc-list">
          <li>trả lời các câu hỏi và thực hiện các yêu cầu.</li>
          <li>liên lạc về xử lý đơn đặt hàng, mua hàng, trả hàng, dịch vụ, quyên góp, khuyến mại, chiến dịch, chương trình, cuộc thi, rút ​​thăm trúng thưởng, phần thưởng và tài khoản.</li>
          <li>thông báo về thương hiệu, sản phẩm, chương trình khuyến mãi, sự kiện hoặc các mục đích khuyến mại khác của chúng tôi.</li>
          <li>liên hệ lại nếu chúng tôi không nhận được phản hồi từ bạn trong một thời gian</li>
          <li>gửi tài liệu quảng cáo / khuyến mại từ bất kỳ chi nhánh nào của chúng tôi, và thay mặt cho các đối tác chiến lược và đối tác thực hiện chương trình khuyến mại của chúng tôi và</li>
          <li>cải thiện trải nghiệm với các sản phẩm và dịch vụ của chúng tôi, chẳng hạn như bằng cách cá nhân hóa trải nghiệm của bạn với chúng tôi</li>
          <li>cho phép bạn tham gia vào các cuộc thăm dò, rút ​​thăm trúng thưởng, khuyến mại, cuộc thi và các chương trình khuyến mãi khác và quản lý các hoạt động này</li>
          <li>gửi thẻ quà tặng hoặc quà tặng cho người khác theo sự hướng dẫn của bạn</li>
          <li>cho phép bạn tham gia chia sẻ xã hội, bao gồm cả nguồn cấp dữ liệu trực tiếp trên mạng xã hội</li>
          <li>thực hiện phân tích, kiểm soát chất lượng, nghiên cứu thị trường và xác định hiệu quả của các trang web, ứng dụng di động, chiến dịch quảng cáo và phát triển các sản phẩm và dịch vụ mới của chúng tôi</li>
        </ul>
        <p>Chúng tôi cũng có thể sử dụng Thông tin Cá nhân mà chúng tôi tin là cần thiết hoặc thích hợp cho các mục đích thiết yếu nhất định, bao gồm:</p>
        <ul class="disc-list">
          <li>tuân thủ luật hiện hành và quy trình pháp lý</li>
          <li>đáp ứng các yêu cầu từ các cơ quan công quyền và chính phủ, bao gồm các cơ quan công quyền và chính phủ bên ngoài quốc gia cư trú của bạn</li>
          <li>phát hiện, ngăn chặn hoặc điều tra các sự cố bảo mật tiềm ẩn hoặc gian lận</li>
          <li>hỗ trợ chức năng của các ứng dụng di động và trang web của chúng tôi</li>
          <li>cung cấp thông tin quan trọng về an toàn sản phẩm và thông báo thu hồi sản phẩm</li>
          <li>thực thi các điều khoản và điều kiện của chúng tôi</li>
          <li>bảo vệ hoạt động của chúng tôi hoặc của các chi nhánh của chúng tôi</li>
          <li>bảo vệ các quyền của chúng tôi, sự riêng tư, an toàn hoặc tài sản, bảo mật và / hoặc của các chi nhánh của chúng tôi, bạn hoặc những người khác</li>
          <li>cho phép chúng tôi theo đuổi các biện pháp khắc phục có sẵn hoặc hạn chế những thiệt hại mà chúng tôi có thể phải gánh chịu.</li>
        </ul>
        <p>Nếu bạn gửi bất kỳ Thông tin Cá nhân nào liên quan đến người khác trên Mạng, bạn đảm bảo rằng bạn có quyền làm như vậy và cho phép chúng tôi sử dụng thông tin theo Chính sách Bảo mật này.</p>
        <p>Cách Thông tin Cá nhân có thể được tiết lộ</p>
        <ol>
          <li>
            <p>Trong phạm vi pháp luật cho phép, tất cả Thông tin Cá nhân có thể được tiết lộ với các nhóm bên thứ ba như sau:</p>
            <ul class="disc-list">
              <li>các chi nhánh của chúng tôi cho các mục đích được mô tả trong Chính sách Bảo mật này</li>
              <li>các bên thứ ba cung cấp dịch vụ của chúng tôi, cung cấp các dịch vụ như lưu trữ trang web, phân tích dữ liệu, xử lý thanh toán, thực hiện đơn hàng, cung cấp cơ sở hạ tầng, dịch vụ CNTT, dịch vụ khách hàng, dịch vụ gửi email, xử lý thẻ tín dụng, dịch vụ kiểm toán và các dịch vụ khác, để cho phép họ cung cấp dịch vụ</li>
              <li>bên thứ ba trong trường hợp tổ chức lại, sáp nhập, bán, liên doanh, chuyển nhượng, chuyển giao hoặc định đoạt khác đối với tất cả hoặc bất kỳ phần nào của hoạt động kinh doanh, tài sản hoặc cổ phiếu của chúng tôi (bao gồm cả việc phá sản hoặc các thủ tục tương tự)</li>
              <li>liên quan đến các mục đích thiết yếu được mô tả ở trên (ví dụ: để tuân thủ các nghĩa vụ pháp lý)</li>
            </ul>
          </li>
          <li>
            <p>Trong phạm vi pháp luật cho phép, tên và thông tin liên hệ của bạn (và các thông tin khác công khai) có thể được tiết lộ với các nhóm bên thứ ba sau:</p>
            <ul class="disc-list">
              <li>đến các bên thứ ba là đối tác chiến lược mà chúng tôi có thể tham gia vào một mối quan hệ riêng biệt cho các hoạt động khuyến mại, được pháp luật cho phép</li>
              <li>đến các bên thứ ba có liên quan đến các chương trình khuyến mại hoặc hoạt động tiếp thị mà bạn có thể tham gia. Trong phạm vi các quy tắc của chương trình khuyến mại liên quan đến việc xử lý Thông tin cá nhân nghiêm ngặt hơn Chính sách bảo mật này, các quy tắc của chương trình khuyến mại sẽ được áp dụng.</li>
            </ul>
          </li>
          <li>
            <p>Trong phạm vi được pháp luật cho phép, thông tin được thu thập thụ động khi bạn sử dụng mạng có thể được tiết lộ với các bên thứ ba sau:</p>
            <ul class="disc-list">
              <li>các nhà quảng cáo trực tuyến và qua email của chúng tôi hoặc các bên cung cấp thứ ba khác mà chúng tôi sử dụng, những người có thể cung cấp cookie, thẻ pixel, đèn hiệu web, GIF rõ ràng hoặc các công nghệ tương tự khác mà họ sử dụng trên Mạng của chúng tôi hoặc các trang web khác để quản lý và cải thiện các chiến dịch quảng cáo trực tuyến và qua quảng cáo qua email của chúng tôi.</li>
            </ul>
          </li>
        </ol>
        <h4>MẠNG CỦA BÊN THỨ BA</h4>
        <p>Các Mạng có thể liên kết đến các trang web, ứng dụng di động, cổng thanh toán và các dịch vụ hoặc nền tảng trực tuyến của bên thứ ba. Các liên kết này chỉ được cung cấp để thuận tiện cho bạn. Chúng tôi không kiểm soát các bên thứ ba đó và không chịu trách nhiệm về nội dung, tính khả dụng hoặc bảo mật của các bên thứ ba. Các bên thứ ba có thể sử dụng thông tin của bạn cho các mục đích riêng của họ, bao gồm cả mục đích hành vi hoặc quảng cáo. Nếu bạn quyết định truy cập vào bất kỳ liên kết nào của bên thứ ba có sẵn trên Mạng hoặc nếu bạn cung cấp Thông tin Cá nhân thông qua các trang web, ứng dụng hoặc nền tảng của bên thứ ba này, bạn thực hiện theo các chính sách bảo mật và điều khoản và điều kiện sử dụng của chủ sở hữu hoặc nhà điều hành của các trang web, ứng dụng hoặc nền tảng của bên thứ ba đó, và không phải được điều chỉnh bởi Chính sách Bảo mật này.</p>
        <h4>CHUYỂN THÔNG TIN CÁ NH N</h4>
        <p>Chúng tôi có thể chuyển Thông tin Cá nhân của bạn đến các máy chủ đặt bên ngoài quốc gia bạn sinh sống hoặc đến các chi nhánh hoặc các bên thứ ba đáng tin cậy khác có trụ sở tại các quốc gia khác để họ có thể xử lý thông tin cá nhân thay mặt chúng tôi. Bằng cách sử dụng Mạng hoặc cung cấp cho chúng tôi Thông tin Cá nhân, bạn đồng ý để chúng tôi làm như vậy theo các điều khoản của Chính sách bảo mật này cũng như các luật và quy định hiện hành về bảo vệ dữ liệu.</p>
        <p>Xin lưu ý rằng nhiều quốc gia không đủ khả năng bảo vệ hợp pháp đối với thông tin cá nhân như có thể có tại quốc gia xuất xứ của bạn. Khi Thông tin Cá nhân của bạn ở một quốc gia khác, nó có thể được truy cập bởi tòa án, cơ quan thực thi pháp luật và an ninh quốc gia ở quốc gia đó theo luật của quốc gia đó. Theo các yêu cầu truy cập hợp pháp như vậy, chúng tôi sẽ đảm bảo rằng việc xử lý Thông tin Cá nhân của bạn bên ngoài quốc gia xuất xứ của bạn đều phải thực hiện các biện pháp bảo vệ thông tin và chỉ được quyền xử lý thông tin đó theo hướng dẫn của chúng tôi.</p>
        <h4>BẢO MẬT</h4>
        <p>Chúng tôi sử dụng các biện pháp vật lý, kỹ thuật và hành chính tiêu chuẩn được thiết kế để giảm nguy cơ mất mát, lạm dụng, truy cập trái phép, tiết lộ hoặc sửa đổi Thông tin Cá nhân của bạn. Tuy nhiên, không có hệ thống hoặc mạng lưới nào có thể được đảm bảo là an toàn 100%. Nếu bạn có lý do để tin rằng tương tác của bạn với chúng tôi không còn an toàn (ví dụ: nếu bạn cảm thấy rằng tính bảo mật của bất kỳ tài khoản nào bạn có thể có với chúng tôi đã bị xâm phạm), vui lòng thông báo ngay cho chúng tôi theo phần "Liên hệ Chúng tôi" bên dưới (lưu ý rằng việc bạn thông báo bằng cách gửi thư bằng đường bưu điện sẽ kéo dài thời gian chúng tôi cần để phản hồi sự cố).</p>
        <h4>LỰA CHỌN VÀ QUYỀN TRUY CẬP CỦA BẠN</h4>
        <p>Lựa chọn về việc chúng tôi sử dụng và tiết lộ Thông tin Cá nhân</p>
        <ul class="disc-list">
          <li>Bạn có thể chọn không nhận email và gửi thư quảng cáo bằng cách thông báo cho chúng tôi về các tùy chọn của bạn khi đăng ký tài khoản hoặc sửa đổi các tùy chọn khuyến mại trực tuyến trong phần quản lý hồ sơ tài khoản của bạn. Để ngừng nhận tiếp thị qua điện thoại, thư, SMS và / hoặc email từ chúng tôi, bạn có thể nhắn tin “DỪNG” trả lời một tin nhắn văn bản từ chúng tôi hoặc nhấp vào “hủy đăng ký” ở cuối email từ chúng tôi. Ngoài ra, bạn có thể cho chúng tôi biết theo hướng dẫn trong phần “Liên hệ Chúng tôi”. Vui lòng nêu rõ loại thông tin liên lạc nào bạn không muốn nhận nữa, và số điện thoại, địa chỉ và / hoặc địa chỉ e-mail có liên quan. Nếu bạn chọn không nhận các tin nhắn tiếp thị từ chúng tôi, chúng tôi vẫn có thể gửi cho bạn các thông báo quản trị quan trọng, chẳng hạn như email về tài khoản của bạn.</li>
          <li>Chúng tôi sẽ không tiết lộ Thông tin Cá nhân cho các bên thứ ba nếu chúng tôi đã nhận và xử lý yêu cầu đó từ bạn. Để gửi một yêu cầu như vậy, vui lòng liên hệ với chúng tôi bằng cách gửi cho chúng tôi một tin nhắn theo hướng dẫn trong phần “Liên hệ Chúng tôi”. Lưu ý rằng chúng tôi có thể tiếp tục chia sẻ Thông tin Cá nhân của bạn với các chi nhánh, nhà cung cấp dịch vụ của chúng tôi, cho các mục đích thiết yếu được mô tả ở trên và các trường hợp khác đã được quy định.</li>
        </ul>
        <p>Cách bạn có thể truy cập hoặc xóa Thông tin Cá nhân của mình</p>
        <ul class="disc-list">
          <li>Bạn có thể yêu cầu một bản sao của những thông tin sau: (1) Các Thông tin Cá nhân của bạn mà chúng tôi đã thu thập; (2) Các nguồn Thông tin Cá nhân được thu thập; (3) Mục đích kinh doanh hoặc thương mại cho việc thu thập hoặc chia sẻ Thông tin Cá nhân; (4) Các bên thứ ba mà chúng tôi chia sẻ Thông tin Cá nhân và các loại Thông tin Cá nhân được chia sẻ; và (5) Các phần cụ thể của Thông tin Cá nhân của bạn mà chúng tôi đã thu thập, sử dụng hoặc tiết lộ (nếu có).</li>
          <li>Bạn có thể yêu cầu chúng tôi xóa Thông tin Cá nhân của mình. Lưu ý rằng các yêu cầu xóa phải tuân theo một số giới hạn nhất định, ví dụ: chúng tôi có thể giữ lại Thông tin Cá nhân khi được pháp luật cho phép, chẳng hạn như cho các mục đích thuế hoặc lưu trữ hồ sơ khác, để duy trì một tài khoản hoạt động và để xử lý các giao dịch và tạo điều kiện cho các yêu cầu của khách hàng.</li>
        </ul>
        <p>Bạn có quyền không bị phân biệt đối xử về dịch vụ hoặc giá cả nếu bạn thực hiện các quyền riêng tư của mình và chúng tôi cũng sẽ không phân biệt đối xử bạn nếu bạn thực hiện chúng.</p>
        <p>Để yêu cầu bản sao Thông tin cá nhân của bạn mà chúng tôi đã thu thập hoặc yêu cầu xóa thông tin của bạn, vui lòng thực hiện theo hướng dẫn trong phần “Liên hệ Chúng tôi” bên dưới. Bạn cần cung cấp tên, địa chỉ email và quốc gia cư trú của mình.</p>
        <p>Khi bạn gửi yêu cầu, chúng tôi sẽ liên hệ với bạn (qua địa chỉ email hoặc số điện thoại di động được cung cấp trong yêu cầu của bạn) để hướng dẫn về cách xác minh yêu cầu, sau đó chúng tôi sẽ kiểm tra thông tin trùng khớp với thông tin trong hồ sơ của chúng tôi và hoàn thành yêu cầu trong thời gian sớm nhất phù hợp với thực tế và phù hợp với bất kỳ luật hiện hành nào. Xin lưu ý rằng có thể mất thêm thời gian để xác minh và thực hiện các yêu cầu do đại lý gửi.</p>
        <p>Nếu bạn có tài khoản với chúng tôi, bạn cũng có thể thực hiện một số thay đổi trực tiếp trong trang hồ sơ tài khoản của mình. Xin lưu ý rằng những thay đổi bạn thực hiện trên hồ sơ tài khoản của mình thông qua Mạng của chúng tôi có thể không phải lúc nào cũng được phản ánh trên các Mạng khác do chúng tôi điều hành.</p>
        <h4>THỜI GIAN LƯU TRỮ</h4>
        <p>Chúng tôi sẽ lưu giữ Thông tin Cá nhân của bạn trong khoảng thời gian cần thiết để thực hiện các mục đích được nêu trong Chính sách Bảo mật này trừ khi một khoảng thời gian lưu giữ lâu hơn được yêu cầu hoặc cho phép bởi các mục đích pháp lý, kiểm toán hoặc tuân thủ.</p>
        <h4>QUYỀN RIÊNG TƯ CỦA TRẺ EM</h4>
        <p>Chúng tôi không cố ý thu thập bất kỳ thông tin nào trên mạng từ bất kỳ trẻ em nào dưới 13 tuổi hoặc cho phép trẻ vị thành niên dưới 18 tuổi tiết lộ Thông tin cá nhân của họ trên Mạng cho chúng tôi. Mạng chủ yếu hướng đến các cá nhân được phép chia sẻ Thông tin cá nhân mà không cần sự đồng ý của cha mẹ. Nếu bạn tin rằng chúng tôi có thể có bất kỳ thông tin nào từ trẻ em dưới độ tuổi hợp pháp trong nước, vui lòng liên hệ với chúng tôi theo hướng dẫn trong phần “Liên hệ Chúng tôi”.</p>
        <p>Chúng tôi có thể yêu cầu thêm sự đồng ý trong quá trình đăng ký tài khoản từ trẻ vị thành niên từ 13 đến 17 tuổi theo luật hiện hành và có liên quan.</p>
        <p>Nếu bạn dưới 18 tuổi và là đã người dùng đã đăng ký trang Mạng, bạn có thể yêu cầu chúng tôi xóa nội dung hoặc thông tin mà bạn đã đăng lên Mạng bằng cách liên hệ với chúng tôi theo hướng dẫn trong phần “Liên hệ Chúng tôi”. Xin lưu ý rằng yêu cầu của bạn không đảm bảo việc loại bỏ hoàn toàn hoặc toàn diện nội dung hoặc thông tin, ví dụ: một người dùng khác trong mạng có thể đã đăng lại nội dung công khai của bạn ở một nơi khác ngoài mạng hoặc trên một trang web khác.</p>
        <h4>CẬP NHẬT CHÍNH SÁCH BẢO MẬT NÀY</h4>
        <p>Chúng tôi có thể cập nhật định kỳ Chính sách Bảo mật này. Vui lòng tham khảo chú thích “CẬP NHẬT MỚI NHẤT” ở đầu trang này để biết thời gian Chính sách Bảo mật này được cập nhật lần cuối. Bất kỳ thay đổi nào đối với Chính sách Bảo mật này sẽ có hiệu lực khi chúng tôi đăng Chính sách Bảo mật đã cập nhật trên Mạng của chúng tôi. Việc bạn sử dụng Mạng sau khi có những thay đổi này có nghĩa là bạn chấp nhận Chính sách Bảo mật đã được cập nhật.</p>
        <h4>LIÊN HỆ CHÚNG TÔI</h4>
        <p>Chúng tôi hoan nghênh các câu hỏi, nhận xét, yêu cầu và mối quan tâm của bạn về quyền riêng tư. Nếu bạn có bất kỳ câu hỏi nào về Chính sách Bảo mật này hoặc dữ liệu của chúng tôi, muốn từ chối nhận bất kỳ liên lạc nào từ chúng tôi hoặc muốn đưa ra yêu cầu liên quan đến việc chúng tôi xử lý Thông tin Cá nhân của bạn, vui lòng liên hệ với chúng tôi theo địa chỉ email <a href="mailto:uyen.nguyen@focusmarketing.vn" class="text-[#36FD00]">uyen.nguyen@focusmarketing.vn</a></p>
      </div>
    </div>
  </StandardLayout>
</template>

<style lang="scss" scoped>
.body-container {
  background-color: #0c150d;
  color: #fff;
  .page-container {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding: 40px 30px;
    font-size: 3.5vw;
    @media (min-width: 769px) {
      font-size: 20px;
      padding-bottom: 50px;
    }
    p {
      margin-bottom: 15px;
    }
    h4{
      padding-top: 15px;
      font-weight: 500;
    }
    ol{
      list-style: decimal;
      padding-left: 20px;
      li{
        margin-bottom: 15px;
      }
      p{
        margin-bottom: 5px;
      }
      ul{
        margin-left: 20px;
      }
    }
    ul{
        margin-left: 20px;
      }
    .lower-latin{
      list-style: lower-latin;
    }
    .disc-list{
      list-style: disc;
    }
    .circle-list{
      list-style: circle;
    }
    .red-box{
      padding: 15px 0;
      p{
        display: inline-block;
        background: #b10019;
        padding: 20px;
        border-radius: 4px;
      }
    }
  }

  .header {
    padding: 0 30px;
    font-size: 3vw;
    padding: 40px 30px 30px 30px;
    padding-bottom: 50px;
    line-height: normal;
    h1 {
      font-style: italic;
      font-size: 6vw;
      margin-bottom: 20px;
    }
    @media (min-width: 769px) {
      font-size: 20px;
      padding-bottom: 70px;
      h1 {
        font-size: 45px;
      }
    }
  }
}
</style>
