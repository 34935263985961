<script setup>
import { Head, Link } from '@inertiajs/inertia-vue3';
import StandardLayout from '@/Layouts/StandardLayout.vue';
import { ref, reactive, onMounted, computed } from 'vue';
import { useStore } from 'vuex'
import { useLocalStorage } from '@tools/app';
import { useI18n } from 'vue-i18n';
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from '@constants/main';

const { t } = useI18n()
const $store = useStore()
const $localStorage = useLocalStorage()

const currentLocale = computed(() => {
  return $store.getters.currentLocale
})
// const langs = computed(() => {
//   return [
//     {
//       label: t('english'),
//       code: 'en'
//     },
//     {
//       label: t('chinese_traditional_hongkong'),
//       code: 'zh-hk'
//     },
//   ]
// })
const langs = computed(() => {
  return [...SUPPORTED_LOCALES]
})

const changeLanguage = code => {
  $store.dispatch('setLocale', code)
}
onMounted(() => {
})
</script>
<style scoped lang="scss">
.lang-item {
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 2px solid #fff;
  }

  &.checked {
    span {
      &::after {
        content: '';
        display: block;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        background-color: #fff;
        border-radius: 100%;
      }
    }
  }
}
</style>
<template>

  <Head title="Register" />
  <StandardLayout :showBack="true">
    <div class="min-h-screen flex flex-col">
      <div class="px-5 pb-5 mb-8">
        <h1 class="font-tccc-head text-2xl font-bold">{{ t('change_language.change_language') }}</h1>
      </div>
      <div class="lang-item px-4 py-5 bg-level-01 text-xl text-primary-02 flex items-center flex justify-between"
        :class="[{ 'border-t border-border': index }, { 'checked': item === currentLocale }]" v-for="(item, index) in langs"
        :key="index" @click="changeLanguage(item)">
        {{ t(`change_language.${item}`) }}
        <span class="w-5 h-5"></span>
      </div>
    </div>
  </StandardLayout>
</template>

<style lang="scss" scoped>
</style>