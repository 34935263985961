<template>
    <StandardLayout
        theme="main"
        :loading="loading"
        :headTitle="t('landing.slogan')"
    >
        <div class="inner-container overflow-hidden">
            <div
                class="main-dash-board text-center flex flex-col items-center text-black"
            >
                <p v-html="t('landing.join_now')"></p>
                <div class="cta-main">
                    <a
                        href="https://wa.me/601154284372?text=Join%20EPIC%20Music%20Break!%20Please%20press%20SEND%20to%20start"
                        target="_blank"
                    >
                        <Button type="cta" class="pos-bottom">
                            <div
                                class="z-10 bg-black text-white w-full h-full rounded-full flex justify-center items-center"
                            >
                                {{ t("join_now") }}
                            </div>
                        </Button>
                    </a>
                </div>
            </div>
        </div>

        <div class="page-container">
            <div class="inner-container">
                <div class="page-row">
                    <div
                        class="section epic-price text-white text-center relative px-4 d-50"
                    >
                        <h2
                            class="sub-head heading-2 font-bold my-4 relative"
                            v-html="t('landing.epic_price')"
                        ></h2>

                        <Carousel
                            :items-to-show="3"
                            :wrap-around="true"
                            class="relative"
                        >
                            <Slide v-for="item in epicPrice" :key="item.key">
                                <div class="carousel__item">
                                    <img :src="item.img" alt="" />
                                    <h4 class="heading-4 font-bold">
                                        {{ item.title }}
                                    </h4>
                                </div>
                            </Slide>

                            <template #addons>
                                <Navigation />
                                <Pagination />
                            </template>
                        </Carousel>
                    </div>

                    <div
                        class="section easy-steps-container text-white relative px-0 d-50"
                    >
                        <h2
                            class="sub-head text-center uppercase heading-2 font-bold my-4"
                            v-html="t('landing.easy_steps')"
                        ></h2>
                        <ul class="easy-steps pt-4">
                            <li>
                                <img :src="t('landing.step_1_img')" />
                                <div>
                                    <h4 class="heading-4 font-bold" v-html="t('landing.step_1_title')"></h4>
                                    <p v-html="t('landing.step_1_desc')"></p>
                                </div>
                            </li>
                            <li class="step-right">
                                <img :src="t('landing.step_2_img')" />
                                <div>
                                    <h4 class="heading-4 font-bold" v-html="t('landing.step_2_title')"></h4>
                                    <p v-html="t('landing.step_2_desc')"></p>
                                </div>
                            </li>
                            <li>
                                <img :src="t('landing.step_3_img')" />
                                <div>
                                    <h4 class="heading-4 font-bold" v-html="t('landing.step_3_title')"></h4>
                                    <p v-html="t('landing.step_3_desc')"></p>
                                </div>
                            </li>
                        </ul>

                        <!-- <p
                            v-html="t('landing.more_info')"
                            class="more-info"
                        ></p> -->

                        <div class="flex flex-wrap justify-center pt-4">
                            <a
                                href="https://wa.me/601154284372?text=Join%20EPIC%20Music%20Break!%20Please%20press%20SEND%20to%20start"
                                target="_blank"
                            >
                                <Button type="cta" class="pos-bottom">
                                    <div
                                        class="z-10 bg-black text-white w-full h-full rounded-full flex justify-center items-center"
                                    >
                                        {{ t("join_now") }}
                                    </div>
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </StandardLayout>
</template>

<script setup>
import { ref, onMounted, reactive, computed } from "vue";
import Button from "@/Components/Button.vue";
import { msalClearAuthStatus } from "@tools/app";
import StandardLayout from "@/Layouts/StandardLayout.vue";
import { useI18n } from "vue-i18n";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { Inertia } from "@inertiajs/inertia";
// import { isLogin } from "@tools/app";

const { t } = useI18n();
const loading = ref(false);
let apiLanguage = localStorage.getItem("locale") || "en";
apiLanguage == "th" ? (apiLanguage = "th") : "";
const isLogin = computed(() => {
    return $store.getters.is_member_logged_in;
});

const member = computed(() => {
    return $store.state?.auth?.member || {};
});

const userCheck = computed(() => {
    return ~~route().params.isNewUser;
});

const state = reactive({
    loading: true,
    memberId: "",
});

const login = () => {
    if (isLogin.value) {
        Inertia.visit(route("ocp.enterPromoCode"));
    } else {
        msalClearAuthStatus();
        loading.value = true;
        console.log(
            "login",
            $store.state.app.azure.policyAuthorities.signUpSignIn
        );
        $store.state.app.msal.loginRedirect({
            authority:
                $store.state.app.azure.policyAuthorities.signUpSignIn.authority,
            extraQueryParameters: {
                ui_locales: "en",
                base_url:
                    window.location.origin ||
                    "https://qa.musicbreaks.coca-cola.com.sg",
            },
        });
    }
};

const logout = async () => {
    try {
        loading.value = true;
        sessionStorage.removeItem("msal.interaction.status");
        await $store.dispatch("logout", { msal: $store.state.app.msal, route });
    } catch (error) {
        console.log(error);
    } finally {
        loading.value = false;
    }
};

onMounted(() => {
    loading.value = false;
    state.memberId = state.memberId || member.value.user_id;
    window.logoutUser = () => {
        logout();
    };
    window.loginUser = () => {
        login();
    };
});

const epicPrice = reactive([
  {
    img: "/images/landing/bumper-prize.png",
    title:
      "K-POP Bumper Prize",
  },
  {
    img: "/images/landing/marshall.png",
    title:
      "Marshall Stanmore III Black",
  },
  {
    img: "/images/landing/analog.png",
    title:
      "AT-LP120XUSB Direct-Drive Turntable (Analog & USB)",
  },
]);
</script>

<style lang="scss" scoped>
.main-dash-board {
    background: url("/images/landing/music-wave.svg") no-repeat center;
    background-size: calc(1000 / 750 * min(100vw, 768));
    min-height: calc(1000 / 750 * min(100vw, 768));
    font-size: calc(25 / 750 * min(100vw, 768));
    padding: 0 calc(220 / 750 * min(100vw, 768));
    padding-top: calc(330 / 750 * min(100vw, 768));
    margin-top: calc(50 / 750 * min(100vw, 768));
    line-height: normal;
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    position: relative;
    margin-left: -30px;
    margin-right: -30px;
    .cta-main {
        position: absolute;
        left: 50%;
        bottom: calc(160 / 750 * min(100vw, 768));
        transform: translateX(-50%);
    }
    @media (min-width: 1025px) {
        max-width: 650px;
        background-size: 650px;
        padding: 0 190px 70px 190px;
        margin-top: 30px;
        min-height: 650px;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 23px;
        .cta-main {
            bottom: 100px;
        }
    }
}
.sub-head {
    margin-bottom: calc(35 / 750 * min(100vw, 768));
    @media (min-width: 1025px) {
        margin-bottom: 40px;
    }
}
.heading-2 {
    font-size: calc(43 / 750 * min(100vw, 768));
    padding: 0 calc(90 / 750 * min(100vw, 768));
    line-height: 1.3;
    @media (min-width: 1025px) {
        font-size: 30px;
        padding: 0;
        line-height: 36px;
        max-width: 380px;
        margin-left: auto;
        margin-right: auto;
    }
}
.heading-4 + p {
    font-size: calc(25 / 750 * min(100vw, 768));
    line-height: 1.3;
    @media (min-width: 1025px) {
        font-size: 16px;
        line-height: 23px;
    }
}

.mission-reward-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 4;
}
.epic-price {
    position: relative;
    &::before {
        content: "";
        background: url("/images/landing/music-bg.svg") no-repeat top;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
</style>

<style lang="scss">
.carousel {
    .carousel__slide {
        padding-top: 5%;
        padding-bottom: 5%;
        transition: 0.3s;
        opacity: 0;
        align-items: inherit;
        img {
            border-radius: 999px;
            border: calc(15 / 750 * min(100vw, 768)) solid #b6011a;
            transform: scale(0.8);
            position: relative;
            transition: 0.4s;
        }
        h4 {
            visibility: hidden;
            opacity: 0;
            min-width: 200px;
            margin-top: calc(60 / 750 * min(100vw, 768));
            transition: 0.4s;
        }
        &--active {
            z-index: 9;
            opacity: 1;
            img {
                border-color: #fff;
                transform: scale(1.1);
                margin: 0;
            }
            h4 {
                visibility: visible;
                opacity: 1;
            }
        }
        &--next,
        &--prev {
            opacity: 1;
        }
        &--next {
            img {
                left: -21%;
            }
        }
        &--prev {
            img {
                right: -21%;
            }
        }
        @media (min-width: 1025px) {
            padding-top: 65px;
            padding-bottom: 20px;
            img {
                border-width: 10px;
                transform: scale(1.1);
            }
            &--active {
                img {
                    transform: scale(1.5);
                }
            }
            h4 {
                margin-top: 100px;
                min-width: inherit;
                font-size: 18px;
                margin-top: 80px;
            }
        }
    }
    .carousel__prev,
    .carousel__next {
        margin: 0;
        outline: none;
        padding: 0;
        width: calc(45 / 750 * min(100vw, 768));
        height: calc(77 / 750 * min(100vw, 768));
        background-repeat: no-repeat;
        background-size: calc(45 / 750 * min(100vw, 768));
        top: calc(240 / 750 * min(100vw, 768));
        .carousel__icon {
            display: none;
        }
    }
    .carousel__prev {
        background-image: url("/images/icons/prev.svg");
    }
    .carousel__next {
        background-image: url("/images/icons/next.svg");
    }
    .carousel__pagination {
        margin: 0;
        &-button {
            padding: 0;
            margin: calc(10 / 750 * min(100vw, 768));
            &:after {
                width: calc(22 / 750 * min(100vw, 768));
                height: calc(22 / 750 * min(100vw, 768));
                background: #fff;
                border-radius: 100px;
            }
            &--active {
                &:after {
                    background: #000;
                }
            }

            @media (min-width: 1025px) {
                margin: 6px;
                &:after {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }

    @media (min-width: 1025px) {
        max-width: 600px;
        margin: 0 auto;
        .carousel__prev,
        .carousel__next {
            width: 32px;
            height: 55px;
            background-size: 32px;
            top: 167px;
        }
    }
}

@media (min-width: 1025px) {
    .page-row {
        display: flex;
        margin: 0 -40px;
    }
}

.page-container {
    .section {
        padding-top: 5%;
        padding-bottom: 5%;
        @media (min-width: 1025px) {
            padding: 40px 40px;
            &.d-50 {
                width: 50%;
            }
        }
    }
}

.easy-steps {
    li {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      &:last-child {
        padding-bottom: 0;
      }
      img {
        width: calc(250 / 750 * min(100vw, 768));
        height: calc(250 / 750 * min(100vw, 768));
        margin-right: 10px;
      }
      div {
        font-size: calc(37 / 750 * min(100vw, 768));
        line-height: 1.2;
        // padding-right: calc(140 / 750 * min(100vw, 768));
        h4 {
          margin-bottom: calc(18 / 750 * min(100vw, 768));
        }
      }
      &.step-right {
        flex-direction: row-reverse;
        text-align: right;
        img {
          margin-left: 10px;
          margin-right: 0;
        }
        // div {
        //   padding-left: calc(140 / 750 * min(100vw, 768));
        //   padding-right: 0;
        // }
      }
      @media (min-width: 1280px) {
        img {
          width: 200px;
          height: 200px;
        }
        div {
          font-size: 23px;
          line-height: 25px;
          // padding-right: 20%;
          h4 {
            font-size: 23px;
            line-height: 25px;
            margin-bottom: 10px;
          }
        }
        &.step-right {
          // div {
          //   padding-left: 20%;
          //   padding-right: 0;
          // }
        }
      }
    }
  }

.easy-steps-container {
    @media (min-width: 1025px) {
        margin-top: -300px;
    }
}

.page-container {
    background-image: url(/images/landing/bg-footer.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: calc(250 / 750 * min(100vw, 768)) !important;
    margin-bottom: calc(-200 / 750 * min(100vw, 768)) !important;
    background-color: #b10019;
    @media (min-width: 1024px) {
        background-image: url(/images/landing/bg-footer-desktop.png);
        background-size: contain;
        margin-bottom: -111px !important;
        padding: 0 30px 250px 30px !important;
    }
}
.inner-container {
    max-width: 1440px !important;
    margin: 0 auto !important;
    width: 100%;
    @media (min-width: 1025px) {
        padding: 0 30px !important;
    }
}

.more-info {
    margin: 30px 0;
    text-align: center;
    font-size: calc(23 / 750 * min(100vw, 768));
    @media (min-width: 1025px) {
        font-size: 16px;
    }
}
</style>
