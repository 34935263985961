<script setup>
import { onMounted, ref, computed } from "vue";

defineProps([
    "modelValue",
    "label",
    "placeholder",
    "disabled",
    "max",
    "type",
    "isError",
    "errorMsg",
    "hasBtnValidator",
]);

defineEmits(["update:modelValue"]);

const input = ref(null);
const isFocus = ref(false);

onMounted(() => {
    if (input.value.hasAttribute("autofocus")) {
        input.value.focus();
    }
});

const clickLabel = (e) => {
    isFocus.value = true;
    input.value.focus();
};

const onFocus = (e) => {
    isFocus.value = true;
};
const onBlur = (e) => {
    if (!e.target.value) {
        isFocus.value = false;
    }
};
</script>

<template>
    <div :class="{ 'text-disabled': disabled }">
        <div
            :class="`input-wrapper relative ${
                hasBtnValidator ? 'has-btn-validator' : ''
            }`"
        >
            <label
                v-if="label"
                :class="[
                    'text-xs font-md absolute left-0 transition-all',
                    { focus: isFocus },
                ]"
                @click="clickLabel"
                >{{ label }}</label
            >
            <input
                class="w-full input bg-transparent focus:outline-0 bg-transparent font-normal px-0 py-1 h-[30px] focus:outline-none"
                :type="type"
                :value="modelValue"
                :disabled="disabled"
                :maxlength=max
                ref="input"
                @focus="(e) => onFocus(e)"
                @blur="(e) => onBlur(e)"
                @input="$emit('update:modelValue', $event.target.value)"
            />
            <slot name="btnValidator" />
        </div>
        <div v-if="isError && errorMsg" class="text-xs text-error mt-1">
            {{ errorMsg }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
label {
    top: -10px;
    font-size: 16px;
    &.focus {
        top: -10px;
        font-size: 10px;
    }
}
.input {
    border-bottom: 1px solid;
}
.input-icon {
    position: relative;
    /* padding-left: 20px; */

    .icon-svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1rem;
        left: 0.25rem;
        display: block;
    }

    input {
        padding-left: 1.75rem;
    }

    .input-wrapper {
        position: relative;
    }
}
</style>
