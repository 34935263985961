<script setup>
import { Link } from "@inertiajs/inertia-vue3";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const $prop = defineProps({
  theme: {
    type: String,
    default: "",
  },
});
const footStyle = computed(() => {
  switch ($prop.theme) {
    case "main":
      return "text-black footer-main-page";
      break;
    default:
      return "text-white bg-red-01";
  }
});
</script>

<style scoped lang="scss"></style>

<template>
  <footer class="mt-auto px-5 pt-2 pb-8 font-tccc-unity z-10" :class="footStyle">
    <div
      class="flex flex-col md:flex-row flex-wrap justify-start md:justify-between w-full"
    >
      <p class="font-bold" v-html="t('footer.all_rights')"></p>
      <div>
        <!-- <Link class="mr-2 mt-2 underline text-center" :href="route('ocp.privacyPolicy')">
          {{ t("footer.privacy_policy") }} </Link
        >| -->
        <a class="mr-2 mt-2 underline text-center" href="https://www.coca-cola.com.sg/policies/privacy-policy" target="_blank">
          {{ t("footer.privacy_policy") }} </a
        >|
        <Link
          class="ml-2 mt-2 underline text-center"
          :href="route('ocp.termsConditions')"
        >
          {{ t("footer.terms_conditions") }}
        </Link>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  font-size: calc(22 / 750 * min(100vw, 768px));
  @media (min-width: 1024px) {
    font-size: 16px;
    padding-left: calc(61 / 1920 * 100vw);
    padding-right: calc(61 / 1920 * 100vw);
  }
}

.footer-main-page {
  z-index: 40;
}
</style>
