<template>
  <StandardLayout :isLoading="loading">

  <div
    class="popup-alert fixed w-screen h-screen top-0 left-0 p-5 flex justify-center items-center bg-black z-10 "
    v-if="failLogin"
  >
    <div class="popup__inner bg-white rounded-md p-10 text-center text-black relative">
      <Button
        type="circle-nav"
        class="popup-close is-active absolute right-0 bottom-[110%]"
        @click="closeAlert"
      />
      <p v-html="t('landing.access_deny')"></p>
    </div>
  </div>
    <div class="body-container overflow-auto min-h-screen flex items-center font-barlow">
      <div class="page-container z-1 relative text-center">
        <p class="oops-img">
          <img src="/images/landing/oops.png" alt="oops" class="inline-block" />
        </p>
        <h1 v-html="t('landing.oops_slogan')" class="font-[700] uppercase"></h1>
        <img src="/images/landing/loading.png" alt="loading" class="loading inline-block" />
        <p class="font-[500] uppercase text-[#08bb0d] oops_desc">{{ t("landing.oops_desc") }}</p>
      </div>
    </div>
  </StandardLayout>
</template>

<script setup>
import { ref, onMounted, reactive, computed } from "vue";
import Button from "@/Components/Button.vue";
import { msalClearAuthStatus } from "@tools/app";
import StandardLayout from "@/Layouts/StandardLayout.vue";
import { useI18n } from "vue-i18n";
import { Inertia } from "@inertiajs/inertia";
import { isLogin } from "@tools/app";
import { useLocalStorage } from "@tools/app";
import { Link } from "@inertiajs/inertia-vue3";

const { t } = useI18n();
const loading = ref(false);
const $localStorage = useLocalStorage();
const failLogin = ref(false);
const showModal = ref(false);

const closeAlert = () => {
  failLogin.value = false;
  $localStorage.remove("alert_login_fail");
};

const member = computed(() => {
  return $store.state?.auth?.member || {};
});

const state = reactive({
  loading: true,
  memberId: "",
});

const login = () => {
  loading.value = true;
  if(isLogin()) {
    if($localStorage.get('USER_WIN')) {
      Inertia.visit(route("ocp.thankYouWin"));
    } else {
      Inertia.visit(route("ocp.thankYouNotWin"));
    }
  } else {
    msalClearAuthStatus();
    $store.state.app.msal.loginRedirect({
      authority: $store.state.app.azure.policyAuthorities.signUpSignIn.authority,
      extraQueryParameters: {
        ui_locales: "vi",
        base_url: window.location.origin || "https://thichthinhich.xomtu.vn",
      },
    });
  }
};

const logout = async () => {
  try {
    loading.value = true;
    sessionStorage.removeItem("msal.interaction.status");
    await $store.dispatch("logout", { msal: $store.state.app.msal, route });
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  loading.value = false;
  state.memberId = state.memberId || member.value.user_id;

  window.logoutUser = () => {
    logout();
  };

  if ($localStorage.get("alert_login_fail")) {
    failLogin.value = true;
  }
});
</script>

<style lang="scss" scoped>
.body-container {
  background-image: url("/images/landing/bg-mobile-oops.jpg");
  background-size: cover;
  background-position: top;
  @media (min-width: 769px) {
    background-image: url("/images/landing/bg-desktop-oops.jpg");
  }
  color: #fff;
  .page-container {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    padding: 40px 30px;
    font-size: 3.5vw;
    @media (min-width: 769px) {
      font-size: 20px;
      padding-bottom: 50px;
    }
    p.oops_desc {
      font-size: 7vw;
      @media (min-width: 769px) {
        font-size: 45px;
      }
    }
    .oops-img{
      img{
        width: 45vw;
        margin-bottom: 6vw;
        @media (min-width: 769px) {
          max-width: 200px;
          margin-right: -40px;
          margin-bottom: -20px;
        }
      }
      @media (min-width: 769px) {
        text-align: right;
      }
    }
    .loading{
      width: 60vw;
      margin-bottom: 8vw;
      @media (min-width: 769px) {
        max-width: 350px;
        margin-bottom: 30px;
      }
    }
    h1 {
      font-size: 8.5vw;
      margin-bottom: 7vw;
      @media (min-width: 769px) {
        font-size: 70px;
        line-height: 90px;
        margin-bottom: 50px;
      }
    }
  }
}
</style>
